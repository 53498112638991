import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { MoreVertical } from "react-feather";
import TablePagination from "@mui/material/TablePagination";
import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import handleSearch from "./index";
import {
  Card as MuiCard,
  CardHeader,
  IconButton,
  Chip as MuiChip,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { spacing } from "@mui/system";
import axios from "axios";

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const StyledTableCell = styled(TableCell)`
  background-color: #f2f2f2;
`;

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

// Data
let id = 0;
function createData(address, upgrade, year, cost, usd, roi, kw, co2) {
  const uniqueId = `${address}-${upgrade}`;
  return { id: uniqueId, address, upgrade, year, cost, usd, roi, kw, co2 };
}
const getRandomStatus = () => {
  const options = ["Already Installed", "Planned", "In Progress", "Completed"];
  return options[Math.floor(Math.random() * options.length)];
};

const activeButtonStyle = {
  backgroundColor: "#50c6fe",
  color: "white",
};

const inactiveButtonStyle = {
  backgroundColor: "white",
  color: "#50c6fe",
};

const DashboardTable4 = ({ title, data2 }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [statuses, setStatuses] = useState({});
  const [activeButton, setActiveButton] = useState(1);
  useEffect(() => {
    if (data2) {
      const initialStatuses = {};
      data2.forEach((currentObject) => {
        currentObject.upgrades.forEach((upgrade) => {
          const uniqueId = `${currentObject.address}-${upgrade.description}`;
          initialStatuses[uniqueId] = "Planned";
        });
      });
      setStatuses(initialStatuses);
    }
  }, [data2]);
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleRemoveBuilding = async () => {
    const building_id = currentObject.address; // Replace with actual address
    try {
      const id_token = localStorage.getItem("id_token"); // Get the token from localStorage
      const response = await axios.post("https://riiseapp.com/api/remove/", {
        building_id,
        id_token,
      });
      console.log(response.data);
      handleSearch("");
      // Handle success (e.g., show success message)
    } catch (error) {
      console.error(error);
      // Handle error (e.g., show error message)
    }
  };
  const handleStatusChange = (id, newStatus) => {
    setStatuses({ ...statuses, [id]: newStatus });
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "Already Installed":
        return "grey";
      case "Planned":
        return "#f7bfc2";
      case "In Progress":
        return "#f4dcbe";
      case "Completed":
        return "#c7e8d4";
      default:
        return "black";
    }
  };

  const currentObject = data2 ? data2[currentPage] : null;
  const rows = currentObject
    ? currentObject.upgrades.map((upgrade, index) =>
        createData(
          currentObject.address,
          upgrade.description,
          upgrade.recommended_install_date,
          upgrade.cost.toLocaleString(),
          upgrade.annual_savings.toLocaleString(),
          upgrade.recoup_time.toFixed(1),
          upgrade.annual_co2_savings.toLocaleString(),
          upgrade.annual_energy_savings.toLocaleString()
        )
      )
    : [];
  const combinedTitle = `${title}: ${
    currentObject ? currentObject.address : "N/A"
  }`;
  return (
    <Card style={{ backgroundColor: "#e1e0e7" }} mb={6}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CardHeader
          title={
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              {title}:{"  "}
              <span style={{ color: "#50c6fe" }}>
                {currentObject ? currentObject.address : "N/A"}
              </span>
            </Typography>
          }
        />
        <Button
          variant="contained"
          style={activeButton === 1 ? activeButtonStyle : inactiveButtonStyle}
          onClick={handleRemoveBuilding}
        >
          Delete Building
        </Button>
      </Box>
      <Paper>
        <TableWrapper style={{ backgroundColor: "#e1e0e7" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Recommended Retrofits</TableCell>
                <TableCell>Install Year (Prevents Stranded Assets)</TableCell>
                <TableCell>Annual Energy Savings</TableCell>
                <TableCell>Annual CO2 Reductions</TableCell>
                <TableCell>Retrofit Costs (USD)</TableCell>
                <TableCell>Annual Savings (USD)</TableCell>
                <TableCell>ROI Break Even Point</TableCell>
                <TableCell>Retrofit Installation Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <StyledTableCell alt>{row.upgrade}</StyledTableCell>
                  <StyledTableCell>{row.year}</StyledTableCell>
                  <StyledTableCell alt>{`${row.kw} Kw`}</StyledTableCell>
                  <StyledTableCell>{`${row.co2} Kg`}</StyledTableCell>
                  <StyledTableCell alt>{`$${row.cost}`}</StyledTableCell>
                  <StyledTableCell>{`$${row.usd}`}</StyledTableCell>
                  <StyledTableCell alt>{`${row.roi} Years`}</StyledTableCell>
                  <TableCell>
                    <Select
                      value={statuses[row.id] || "Planned"}
                      onChange={(e) =>
                        handleStatusChange(row.id, e.target.value)
                      }
                      style={{
                        backgroundColor: getStatusColor(
                          statuses[row.id] || "Planned"
                        ),
                      }}
                    >
                      <MenuItem value="Already Installed">
                        Already Installed
                      </MenuItem>
                      <MenuItem value="Planned">Planned</MenuItem>
                      <MenuItem value="In Progress">In Progress</MenuItem>
                      <MenuItem value="Completed">Completed</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[]} // empty array as we are paginating through objects not rows
        component="div"
        count={data2 ? data2.length : 0}
        rowsPerPage={1} // 1 object per page
        page={currentPage}
        onPageChange={handleChangePage}
      />
    </Card>
  );
};

export default DashboardTable4;
