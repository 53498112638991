import React, { useEffect, Suspense, lazy } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useState } from "react";

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  InputBase,
  Button,
  CircularProgress,
} from "@mui/material";
import { spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";

//import Actions from "./Actions";
//import BarChart from "./BarChart";
import LineChart from "./LineChart";
import LineChart2 from "./LineChart2";
import DoughnutChart from "./DoughnutChart";
import DoughnutChart2 from "./DoughnutChart2";
import Stats from "./Stats";
import Stats2 from "./Stats2";
import Table from "./Table";
//import Table2 from "./Table2";
import Table4 from "./Table4";
import AreaChart from "./AreaChart";
import AreaChart2 from "./AreaChart2";
import AreaChart3 from "./AreaChart3";
//import WorldMap from "../Analytics/WorldMap";
import { Search as SearchIcon } from "react-feather";
import { darken } from "polished";
//import SimpleMap2 from "../Analytics/GoogleMap2";
import Table3 from "./Table3";
import loadinggif from "./output-onlinegiftools.gif";
import ErrorBoundary from "./error";

const SimpleMap2 = lazy(() => import("../Analytics/GoogleMap2"));

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const StyledPage = styled.div`
  background-color: grey;
  min-height: 100vh;
  width: 100vw;
`;
/*
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`; */

const Search = styled.div`
  border-radius: 8px; // Increase corner rounding
  border: 2px solid #50c6fe; // Add a blue border
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 50%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`;

const defaultStyle = {
  backgroundColor: "#50c6fe",
  color: "white",
  marginLeft: "10px",
};

const hoverStyle = {
  backgroundColor: "white",
  color: "#50c6fe",
  marginLeft: "10px",
};

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 660px;
  }
`;

function getROI(CAPX, OPEX) {
  const outputArray = [0];
  let rate = 0;
  let years = 1;
  let prevCapx = CAPX[0];

  for (let i = 1; i < CAPX.length; i++) {
    let newValue = outputArray[i - 1];

    // Calculate OPEX savings from the previous year to this year
    rate += OPEX[i - 1] - OPEX[i];

    // Check for a change in CAPEX
    if (CAPX[i] !== prevCapx) {
      // Subtract the CAPEX change from the new value
      newValue -= CAPX[i] - prevCapx;
      // Update previous CAPEX for next iteration
      prevCapx = CAPX[i];
    }

    // Add the rate (OPEX savings) to the newValue
    newValue += rate;

    // Add newValue to outputArray
    outputArray.push(newValue);

    // Check if ROI has become positive
    if (newValue >= 0 && outputArray[i - 1] < 0) {
      years = i; // Years taken for ROI to become positive
    }
  }

  return { outputArray, years };
}

const calculateInfo = (data) => {
  let totalCo2 = 0;
  let totalOptimizedCo2 = 0;
  let totalUpgradeCost = 0;
  let totalAnnualSavings = 0;
  let totalInitialPEV = 0;
  let totalOptimizedPEV = 0;
  let totalPercentEnergyReduction = 0;
  let totalUpgradeInfo = {};
  for (const item of data) {
    totalCo2 += item.co2 * item.sqft;
    totalOptimizedCo2 += item.optimized_Co2 * item.sqft;
    totalInitialPEV += item.pev;
    totalOptimizedPEV += item.optimized_PEV;

    const { co2Array, capexCosts, opexCosts } = calculateArrays(data);

    //Calculate ROI and add it to the item
    const { outputArray, roi } = getROI(capexCosts, opexCosts);
    item.roiOutputArray = outputArray; // Add the ROI array
    item.roiYearsToPositive = years;  // Add the ROI years to positive


    for (const upgrade of item.upgrades) {
      totalUpgradeCost += upgrade.cost; // Assuming the cost field exists in upgrades
      totalAnnualSavings += upgrade.annual_savings;
      const desc = upgrade.description;
      if (totalUpgradeInfo[desc]) {
        // Description already exists, accumulate the savings
        totalUpgradeInfo[desc].annual_savings += upgrade.annual_savings;
        totalUpgradeInfo[desc].annual_co2_savings += upgrade.annual_co2_savings;
      } else {
        // Description doesn't exist, add a new entry
        totalUpgradeInfo[desc] = {
          annual_savings: upgrade.annual_savings,
          annual_co2_savings: upgrade.annual_co2_savings,
        };
      }
    }
  }
  totalPercentEnergyReduction =
    1 - (totalInitialPEV - totalOptimizedPEV) / totalInitialPEV;
  return {
    totalCo2,
    totalOptimizedCo2,
    totalUpgradeCost,
    totalAnnualSavings,
    totalPercentEnergyReduction,
    totalUpgradeInfo,
    //roi,
    //capexCosts,
  };
};

const calculateArrays = (data) => {
  // Initialize arrays for 2022 to 2050 with 29 years
  const co2Array = Array(29).fill(0);
  const capexCosts = Array(29).fill(0);
  const opexCosts = Array(29).fill(0);

  // Calculate the initial values for CO2 and OPEX based on data
  let totalCo2 = 0;
  let totalPev = 0;
  let totalsqm = 0;

  for (const item of data) {
    totalCo2 += item.co2;
    totalPev += item.pev;
    totalsqm += item.sqft;
  }

  const avgCo2 = totalCo2 / data.length;
  const totalOpex = totalPev / data.length;

  // Initialize the first values in the arrays
  co2Array[0] = parseFloat(avgCo2.toFixed(0));
  opexCosts[0] = parseFloat(totalOpex.toFixed(0) * totalsqm);
  capexCosts[0] = 0;

  // Populate the arrays based on upgrades
  for (let year = 2023; year <= 2050; year++) {
    const index = year - 2022;
    for (const item of data) {
      for (const upgrade of item.upgrades) {
        const recommendedYear = parseInt(upgrade.recommended_install_date, 10);

        if (recommendedYear === year) {
          const sub = upgrade.annual_co2_savings / (item.sqft * data.length);
          co2Array[index] = parseFloat((co2Array[index] - sub).toFixed(0));
          console.log(`here ${co2Array[index]}`);
          opexCosts[index] = parseFloat(
            (opexCosts[index] - upgrade.annual_energy_savings).toFixed(0)
          );
          capexCosts[index] = parseFloat(
            (capexCosts[index] + upgrade.cost).toFixed(0)
          );
        }
      }
    }

    // Carry over values from the previous year if this is not the first year
    if (index > 0) {
      console.log(` ${co2Array[index - 1]} - ${co2Array[index]} `);
      co2Array[index] = co2Array[index - 1] + co2Array[index];
      opexCosts[index] = opexCosts[index - 1] + opexCosts[index];
      capexCosts[index] = capexCosts[index - 1] + capexCosts[index];
    }
  }

  return { co2Array, capexCosts, opexCosts };
};

function EPCToNumber(rating) {
  switch (rating) {
    case "A":
      return 12;
    case "B":
      return 10;
    case "C":
      return 8;
    case "D":
      return 6;
    case "E":
      return 4;
    case "F":
      return 2;
    default:
      return 0;
  }
}

//xyz
const AssetImprovementByEPC = (data) => {
  let initialSum = 0;
  let optimizedSum = 0;

  // Loop through each object in the array
  data.forEach((item) => {
    initialSum += EPCToNumber(item.initial_EPC_rating);
    optimizedSum += EPCToNumber(item.optimized_EPC_rating);
  });

  // Calculate averages
  const initialEPCAverage = initialSum / data.length;
  const optimizedEPCAverage = optimizedSum / data.length;

  return optimizedEPCAverage - initialEPCAverage;
};

function ratingToNumber(rating) {
  switch (rating) {
    case "Pass":
      return 1;
    case "Good":
      return 2;
    case "Very Good":
      return 3;
    case "Excellent":
      return 4;
    case "Outstanding":
      return 5;
    default:
      return 0;
  }
}
const calculateBreeam = (data) => {
  let initialSum = 0;
  let optimizedSum = 0;

  // Loop through each object in the array
  data.forEach((item) => {
    initialSum += ratingToNumber(item.initial_BREEAM_rating);
    optimizedSum += ratingToNumber(item.optimized_BREEAM_rating);
  });

  // Calculate averages
  const initialBreeamAverage = initialSum / data.length;
  const optimizedBreeamAverage = optimizedSum / data.length;

  return { initialBreeamAverage, optimizedBreeamAverage };
};

function normalizeArray(arr) {
  // Check if the array is empty
  if (arr.length === 0) {
    return null;
  }

  // Find the minimum and maximum values in the array
  const minValue = Math.min(...arr);
  const maxValue = Math.max(...arr);

  // Check for a degenerate case where all values in the array are the same
  if (minValue === maxValue) {
    return null;
  }

  // Normalize the array
  const normalizedArr = arr.map(
    (value) => (value - minValue) / (maxValue - minValue)
  );
  return normalizedArr;
}

const getPercentReductions = (data) => {
  let totalInitialPEV = 0;
  let totalOptimizedPEV = 0;
  let totalInitalCo2 = 0;
  let totalOptimizedCo2 = 0;

  data.forEach((item) => {
    totalInitialPEV += item.pev;
    totalOptimizedPEV += item.optimized_PEV;
    totalInitalCo2 += item.co2;
    totalOptimizedCo2 += item.optimized_Co2;
  });
  const percentCo2 = (
    ((totalInitalCo2 - totalOptimizedCo2) / totalInitalCo2) *
    100
  ).toFixed(0);
  const percentOpex = (
    ((totalInitialPEV - totalOptimizedPEV) / totalInitialPEV) *
    100
  ).toFixed(0);

  return { percentCo2, percentOpex };
};
const activeButtonStyle = {
  backgroundColor: "#50c6fe",
  color: "white",
};

const inactiveButtonStyle = {
  backgroundColor: "white",
  color: "#50c6fe",
};
function Default() {
  const { t } = useTranslation();
  const [hover, setHover] = useState(false);
  const [input, setInput] = useState("");
  const [extractedData, setextractedData] = useState([]);
  const [sumOfCo2, setSumOfCo2] = useState(0);
  const [sumOfOptimizedCo2, setSumOfOptimizedCo2] = useState(0);
  const [sumOfUpgrades, setSumOfUpgrades] = useState(0);
  const [annualSavings, setAnnualSavings] = useState(0);
  const [totalPercentEnergyReduction, setTotalPercentEnergyReduction] =
    useState(0);
  const [totalUpgradeInfo, setTotalUpgradeInfo] = useState(0);
  const [co2Array, setCo2Array] = useState([]);
  const [capexCosts, setCapexCosts] = useState([]);
  const [opexCosts, setOpexCosts] = useState([]);
  const [initialBreeamAverage, setInitialBreeamAverage] = useState([]);
  const [optimizedBreeamAverage, setOptimizedBreeamAverage] = useState([]);
  const [normalizedCo2Array, setNormalizedCo2Array] = useState([]);
  const [normalizedCapexCosts, setNormalizedCapexCosts] = useState([]);
  const [normalizedOpexCosts, setNormalizedOpexCosts] = useState([]);
  const [percentCo2, setPercentCo2] = useState(0);
  const [percentOpex, setPercentOpex] = useState(0);
  const [returns, setReturns] = useState([]);
  const [normalizedReturns, setNormilizedReturns] = useState([]);
  const [years, setYears] = useState(0);
  const [activeButton, setActiveButton] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [userCredits, setUserCredits] = useState(0);
  const [assetValueIncrease, setAssetValueIncrease] = useState(0); //xyz

  const handleChange = (event) => {
    setInput(event.target.value); // Update the state with the input value
  };
  const handleMarkersClick = (clickedMarkers) => {
    console.log("Clicked Markers:", clickedMarkers);
  };

  const processData = (data) => {
    const {
      totalCo2,
      totalOptimizedCo2,
      totalUpgradeCost,
      totalAnnualSavings,
      totalPercentEnergyReduction,
      totalUpgradeInfo,
    } = calculateInfo(data);
    setSumOfCo2(totalCo2);
    setSumOfOptimizedCo2(totalOptimizedCo2);
    setSumOfUpgrades(totalUpgradeCost);
    setAnnualSavings(totalAnnualSavings);
    const improvement = AssetImprovementByEPC(data); //xyz
    console.log("Calculated Improvement:", improvement);
    setAssetValueIncrease(improvement); //xyz
    setTotalPercentEnergyReduction(totalPercentEnergyReduction);
    setTotalUpgradeInfo(totalUpgradeInfo);
    console.log("totalUpgradeInfo", totalUpgradeInfo);
    const { co2Array, capexCosts, opexCosts } = calculateArrays(data);
    setCo2Array(co2Array);
    setCapexCosts(capexCosts);
    setOpexCosts(opexCosts);
    const { outputArray, years } = getROI(capexCosts, opexCosts);
    setReturns(outputArray);
    setYears(years);
    const { initialBreeamAverage, optimizedBreeamAverage } =
      calculateBreeam(data);
    setOptimizedBreeamAverage(optimizedBreeamAverage);



    //normalize arrays for magic graph
    const normalizedArr = normalizeArray(co2Array);
    setNormalizedCo2Array(normalizedArr);
    const normalizedArr2 = normalizeArray(capexCosts);
    setNormalizedCapexCosts(normalizedArr2);
    const normalizedArr3 = normalizeArray(opexCosts);
    setNormalizedOpexCosts(normalizedArr3);
    const normalizedArr4 = normalizeArray(returns);
    setNormilizedReturns(normalizedArr4);
    // console.log("CO2 Array:", normalizedArr);
    // console.log("Capex Costs:", normalizedArr2);
    // console.log("Opex Costs:", normalizedArr3);
    const { percentCo2, percentOpex } = getPercentReductions(data);
    setPercentCo2(percentCo2);
    setPercentOpex(percentOpex);
  };

  useEffect(() => {
    handleSearch(new Event("init")); // Call with a dummy event
  }, []);

  const handleSearch = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const id_token = localStorage.getItem("id_token"); // Assuming the token is stored in localStorage
      const response = await axios.post(
        "https://riiseapp.com/api/buildings/",
        {
          input: input,
          id_token,
        },
        {
          timeout: 300000, // Timeout after 10000ms or 10 seconds
        }
      );
      setInput("");
      setUserCredits(response.data.credits);
      // handle your response here
      console.log(response);
      const extractedData = response.data.buildings.map((item) => ({
        id: item.id,
        address: item.address,
        pev: item.initial_PEV,
        co2: item.initial_Co2, // Correct the case to match your object data
        initial_BREEAM_rating: item.initial_BREEAM_rating,
        initial_EPC_rating: item.initial_EPC_rating,
        initial_stranded_risk: item.initial_stranded_risk,
        lat: item.lat,
        lng: item.lng,
        main_heating: item.main_heating,
        optimized_BREEAM_rating: item.optimized_BREEAM_rating,
        optimized_Co2: item.optimized_Co2,
        optimized_EPC_rating: item.optimized_EPC_rating,
        optimized_PEV: item.optimized_PEV,
        optimized_stranded_risk: item.optimized_stranded_risk,
        sqft: item.sqft,
        upgrades: item.upgrades, // Assuming this is an array of objects, it will be copied as is.
        designation: item.designation,
        leed_cert: item.leed_cert,
        opex_costs: item.opex_costs,
      }));

      console.log("extractedData", extractedData);
      // Store extracted data in sessionStorage
      sessionStorage.setItem("extractedData", JSON.stringify(extractedData));

      // Retrieve unchecked addresses from sessionStorage
      let uncheckedAddresses = JSON.parse(
        sessionStorage.getItem("deselectedAddresses") || "[]"
      );

      // Filter extractedData to exclude buildings with addresses in uncheckedAddresses
      let filteredData = extractedData.filter(
        (building) => !uncheckedAddresses.includes(building.address)
      );
      setextractedData(filteredData);
      // get data for top elements
      processData(extractedData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Stop loading whether success or fail
    }
  };
  return (
    <React.Fragment>
      <Helmet title="Default Dashboard" />
      <Grid
        container
        spacing={6}
        direction="row"
        justifyContent="space-between"
        container
        spacing={6}
      >
        <Grid item xs={5}>
          <Typography variant="h3" gutterBottom color={"#50c6fe"}>
            Global Portfolio Reporting
          </Typography>
          <Typography variant="subtitle1" color={"#7b7c82"}>
            Powered By Satellite, AI & Big Data
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <div
            style={{
              display: "flex",
              flexDirection: "column", // Stack children vertically
              alignItems: "center", // Center align children
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%", // Full width for the search bar container
              }}
            >
              <Typography
                variant="h3"
                style={{
                  color: "#7b7c82",
                  fontSize: 16,
                  marginRight: "20px",
                  whiteSpace: "nowrap",
                }}
              >
                Global Search
              </Typography>
              {isLoading ? (
                <img
                  src={loadinggif}
                  alt="Loading..."
                  style={{ width: 100, height: 100 }}
                />
              ) : (
                <form
                  onSubmit={handleSearch}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Search>
                    <Input
                      placeholder={t("Address, Postcode/Zipcode")}
                      value={input}
                      onChange={handleChange}
                    />
                  </Search>
                  <Button
                    type="submit"
                    variant="contained"
                    style={hover ? hoverStyle : defaultStyle}
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                  >
                    Search
                  </Button>
                </form>
              )}
            </div>
            <Typography
              variant="caption" // Change to a smaller variant
              style={{
                color: "#7b7c82",
                marginBottom: "10px", // Add some margin below
              }}
            >
              Remaining Credits: {userCredits}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="Asset Value"
            tag=""
            amount={`${assetValueIncrease.toFixed(2)}%`} //xyz
            chip="Average"
            percentagetext=""
            percentagecolor="#FFFFFF"
            //illustration="/static/img/illustrations/waiting.png"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats2
            title="ROI"
            tag=""
            amount={`${years} Years`}
            chip="Average"
            percentagetext={`${(100 / years).toFixed(2)}%`}
            percentagecolor={green[500]}
            //illustration="/static/img/illustrations/waiting.png"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="CAPEX Costs"
            tag=""
            amount={`$${sumOfUpgrades.toLocaleString()}`}
            chip="Up Front"
            percentagetext=""
            percentagecolor="#FFFFFF"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="CO2 Reduction"
            tag="Percent Reduction"
            amount={`${(sumOfCo2 - sumOfOptimizedCo2).toLocaleString()} Kg`}
            chip="Yearly"
            percentagetext={`-${percentCo2}%`}
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl>
          <Stats
            title="OPEX Savings"
            tag="Energy Usage"
            amount={`$${(annualSavings.toFixed(0) / 12).toLocaleString()}`}
            chip="Monthly"
            percentagetext={`-${percentOpex}%`}
            percentagecolor={red[500]}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={8} style={{ paddingBottom: "20px" }}>
        {extractedData ? (
          <SimpleMap2 data={extractedData} />
        ) : (
          <div>Loading map data...</div>
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6} lg={6}>
          <Table title={"Current Portfolio"} data={extractedData} />
        </Grid>
        <Grid item xs={6} lg={6}>
          <Table3 title={"Optimized Portfolio"} data={extractedData} />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={6} lg={6}>
          <DoughnutChart
            title={"Optimized OPEX Savings"}
            upgradeInfo={totalUpgradeInfo}
            innerLable={"OPEX Savings"}
            tabelLables={[
              "Product",
              "OPEX Reduction (USD)",
              "Percent Reduction",
            ]}
            percent={percentOpex}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <DoughnutChart2
            title={"Optimized CO2 Reductions"}
            upgradeInfo={totalUpgradeInfo}
            innerLable={"CO2 Savings"}
            tabelLables={[
              "Product",
              "Annual CO2 Reduction",
              "Percent Reduction",
            ]}
            percent={percentCo2}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={12}>
          <AreaChart
            title={"Stranded Risk Chart (CRREM)"}
            description={"Shows How Asset Compares To Government Regulations"}
            fullYData={co2Array}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6} lg={6}>
          <LineChart
            title={"OPEX Costs"}
            dataset={opexCosts}
            lineColor="#50c6fe"
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <LineChart
            title={"CAPEX Costs"}
            dataset={capexCosts}
            lineColor="#86d184"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6} lg={6}>
          <AreaChart3
            title={"Asset Value Increase"}
            description={"Coming Soon!"}
            ydata={[]}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <AreaChart3
            title={"ROI"}
            description={"Investments And Returns Over Time"}
            fullYData={returns}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={12}>
          <Table4 title={"Retrofit Recommendations"} data2={extractedData} />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={4}>
        <LineChart2
          title={"Portfolio Overview"}
          fy1={normalizedCapexCosts}
          fy2={normalizedOpexCosts}
          fy3={normalizedCo2Array}
          fy4={normalizedReturns}
        />
      </Grid>
    </React.Fragment>
  );
}

export default Default;
